<template>
	<div>
		<div class="row">
	        <div class="col-12">
	            <h4>{{ $t('monte.create_contact') }}</h4>
	        </div>
	    </div>
		<form @submit.prevent="checkForm" id="addTiers">
			<div class="row">
				<div class="col-12" v-if="form_message !== ''">
					<ErrorAlert :messageI18n="form_message" />
				</div>

				<div class="col-12" >
					<div class="form-group">
						<label for="contact_civility" class="col-form-label">{{ $t("contact.civility") }} *</label>
						<e-select
							id="contact_civility"
							v-model="form.contact_civility"
							:options="civilities"
							:allow-empty="false"
							:show-labels="false"
						/>
					</div>
					<div class="row">
						<div class="col-6">
							<div class="form-group">
								<label for="contact_firstname" class="col-form-label">{{ $t("contact.firstname") }}</label>
								<input type="text" id="contact_firstname" class="form-control" v-model="form.contact_firstname">
							</div>
						</div>
						<div class="col-6">
							<div class="form-group">
								<label for="contact_lastname" class="col-form-label">{{ $t("contact.lastname") }}</label>
								<input type="text" id="contact_lastname" class="form-control" v-model="form.contact_lastname">
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<div class="form-group">
						<label for="contact_address1" class="col-form-label">{{ $t("contact.address1") }}</label>
						<input type="text" id="contact_address1" class="form-control" v-model="form.contact_address1">
					</div>
				</div>
				<div class="col-12">
					<div class="form-group">
						<label for="contact_address2" class="col-form-label">{{ $t("contact.address2") }}</label>
						<input type="text" id="contact_address2" class="form-control" v-model="form.contact_address2">
					</div>
				</div>
				<div class="col-4">
					<div class="form-group">
						<label for="contact_postalcode" class="col-form-label">{{ $t("contact.postalcode") }}</label>
						<input type="text" id="contact_postalcode" class="form-control" v-model="form.contact_postalcode">
					</div>
				</div>
				<div class="col-8">
					<div class="form-group">
						<label for="contact_town" class="col-form-label">{{ $t("contact.town") }}</label>
						<input type="text" id="contact_town" class="form-control" v-model="form.contact_town">
					</div>
				</div>
				<div class="col-12">
					<div class="form-group">
						<label for="contact_country" class="col-form-label">{{ $t("contact.country") }}</label>
						<CountryInput v-model="form.contact_country"></CountryInput>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<div class="form-group">
						<label for="contact_mail" class="col-form-label">{{ $t("contact.email") }}</label>
						<input type="email" id="contact_mail" class="form-control" v-model="form.contact_mail">
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<label class="col-form-label">{{ $t("contact.phone") }}</label>
				</div>

				<div class="col-12 phone mb-3" v-for="(phone, key_phone) in form.phones" v-bind:key="key_phone">
					<div class="row no-gutters">
						<div class="col-auto pr-2">

							<phoneInput v-model="form.phones[key_phone]"></phoneInput>

						</div>
						<div class="col pr-2">
							<div class="input-group">
								<div class="input-group-prepend">
									<b-form-input type="text" class="form-control" style="width: 5em;" v-model="phone.phone_indicatif"/>
								</div>
								<input type="text" class="form-control" v-model="phone.phone_numero">
							</div>
						</div>
						<div class="col-3">
							<e-select
								id="phone_type"
								v-model="phone.phone_type"
								:options="[
									$t('tiers.phone_type.fixe'),
									$t('tiers.phone_type.portable')
								]"
								:allow-empty="false"
								:show-labels="false"
							/>
						</div>
					</div>
				</div>
				<div class="col-12 text-right">
					<a href="#" @click.prevent="addPhoneNumber()">{{ $t('global.ajouter_numero_telephone') }} <font-awesome-icon :icon="['fal', 'phone-plus']"/></a>
				</div>
			</div>

			<div class="row">
				<div class="col-12">
					<div class="form-group">
						<label for="tierscontact_poste" class="col-form-label">{{ $t("contact.poste") }}</label>
						<e-select
                            v-model="fonctions_formatted_flat"
                            id="tierscontact_poste"
                            track-by="name"
                            label="name"
                            :placeholder="labelFonctionTiersContactVat"
                            :deselectLabel="deselectLabel"
                            :selectedLabel="selectedLabel"
                            :selectLabel="enter_to_select"
                            :options="fonctions"
                            :searchable="true"
                            :show-labels="false"
                            :allow-empty="true"
                            :multiple="true"
                        >
                            <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                        </e-select>
					</div>
				</div>

				<div class="col-12" v-if="boolean_fonction">
					<div class="form-group">
						<label>{{ $t("contact.fonction_perso") }}</label>
						<input
							type="text"
							class="form-control"
							v-model="fonction_perso[key].name"
							v-for="(perso, key) in fonction_perso"
							:key="key"
						>
					</div>
				</div>
				<div class="col-12 text-right">
					<a href="#" @click.prevent="addFonctionInput()">{{ $t('contact.ajouter_fonction') }} <font-awesome-icon :icon="['fal', 'user-visor']"/></a>
				</div>
			</div>

			<div class="text-center">
				<button type="submit" class="btn btn-primary rounded-pill">
                    {{ $t('global.ajouter') }} <font-awesome-icon :icon="['fal', 'plus-circle']" class="ml-1"/>
                </button>
                <b-button @click="cancel" class="btn btn-primary rounded-pill ml-1">
					{{ $t('global.annuler') }} <font-awesome-icon :icon="['fal', 'times']" class="ml-1"/> 
				</b-button>
			</div>
		</form>
	</div>
</template>

<script type="text/javascript">
	import Vue from 'vue'

	import Countries from '@/assets/lang/countries/countries'
	import Indicatif from '@/assets/lang/countries/indicatif'

	import Navigation from "@/mixins/Navigation.js"
	import Tools from "@/mixins/Tools.js"
	import Contact from '@/mixins/Contact.js'
	import Phone from '@/mixins/Phone.js'
	import Fonction from '@/mixins/Fonction.js'
	import Tiers from '@/mixins/Tiers.js'
	import Horse from "@/mixins/Horse.js"
    import Shutter from "@/mixins/Shutter.js"
    import MonteShutters from '@/mixins/shutters-manager/Monte'
    import Common from '@/assets/js/common.js'

	import { parsePhoneNumberFromString as parseMax, AsYouType } from 'libphonenumber-js/max'

	export default {
		name: "ContactFormMonte",
		props: ['horse_id'],
		mixins: [Navigation, Tools, Contact, Phone, Fonction, Tiers, Horse, Shutter, MonteShutters],
		data () {
			return {
				deselectLabel: this.getTrad("global.press_enter_to_remove"),
				selectedLabel: this.getTrad("global.selected_label"),
				enter_to_select: this.getTrad("global.press_enter_to_select"),
				labelFonctionTiersContactVat: this.getTrad("contact.search_fonction"),

				form_message: "",
				errors: [],
				form: {},
				countries: {},
				country_choosen: false,
				fonctions: [],
				fonctions_formatted_flat: [],
				default_form: {
					contact_firstname: '',
					contact_lastname: '',
					contact_civility: 'Mr',
					contact_mail: '',
					contact_address1: '',
					contact_address2: '',
					contact_postalcode: '',
					contact_town: '',
					contact_country: '75',
					contact_website: '',
					contact_entity: false,
					tierscontact_poste: "",
					tierscontact_favoris: false,
					phones: [
						{
							phone_indicatif: '+33',
							phone_numero: '',
							phone_type: this.getTrad('tiers.phone_type.fixe'),
							phone_country: "FRA"
						},
						{
							phone_indicatif: '+33',
							phone_numero: '',
							phone_type: this.getTrad('tiers.phone_type.portable'),
							phone_country: "FRA"
						}
					],
					fonctions_formatted: []
				},
				boolean_fonction: false,
				fonction_perso: [
					{
						name: ""
					}
				],
				civilities: Common.getAllCivilities(),
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
				let fonctions = await this.loadFonction()
				for(var i = 0; i < fonctions.length; i++) {
					this.fonctions.push({name: this.getTrad(fonctions[i].fonction_label), id: fonctions[i].fonction_id })
				}
				this.addOrEditContact(this.horse_id)
			},
			async addOrEditContact(horse_id) {
				this.form_message = ""
				this.indicatifs = Indicatif
				this.countries = Countries[Vue.i18n.locale()].label
				this.countries_iso = Countries[Vue.i18n.locale()].iso_3
				this.fonction_perso = [{
					name: ""
				}]

				this.boolean_fonction = false
				this.form = this.deppCloneObj(this.default_form)
				
				this.fonctions_formatted_flat = []

				this.form.tiers_id = null
				this.form.horse_id = horse_id
				this.country_choosen = true
			},
			async checkForm() {
				this.form_message = ""
				this.errors = []
				if(!this.form.contact_civility) {
					this.errors.push("civility")
				}

				const phones = this.form.phones
				if(phones) {
					for(let i = 0; i < phones.length; i++) {
						if(phones[i].phone_numero) {
							if(!parseMax(phones[i].phone_indicatif+phones[i].phone_numero).isValid()) {
								this.errors.push('phone')
								this.failureToast("toast.phone_not_valid")
							}
						}
					}
				}

				if(this.errors.length == 0) {
					let result = null
					let params_to_send = {
						"contact": this.form,
						"fonction_perso": this.fonction_perso
					}

					try {
						result = await this.addHorseContactFunction(this.form.horse_id, params_to_send)
					} catch (error) {
						this.form_message = "error.LEP"
					}

					this.successToast()
                    this.ok(result)
                    this.shutterPanel().close('monte-contact-ajout')
				} else {
					this.form_message = "formulaire.erreur_champs_non_remplis"
				}
			},
			async addHorseContactFunction(horse_id, params_to_send) {
				const db = this.$storage.db
				return await db.transaction('rw', [db.contact, db.phone, db.horse_contact, db.horse_contact_fonction, db.fonction], async () => {
					const contact_id = await this.addContact(params_to_send.contact)
					const phones = params_to_send.contact.phones
					if(phones) {
						for(let i = 0; i < phones.length; i++) {
							if(phones[i].phone_numero) {
								phones[i].contact_id = contact_id
								phones[i].tiers_id = null
								await this.addPhone(phones[i])
							}
						}
					}

					if(horse_id) {
						const horsecontact_id = await this.addHorseContact(horse_id, contact_id)

						const fonctions = params_to_send.contact.fonctions_formatted
						if(fonctions) {
							for (let y = 0; y < fonctions.length; y++) {
								await this.addHorseContactFonction(fonctions[y].id, horsecontact_id)
							}
						}

						const fonctions_perso = params_to_send.fonction_perso
						let fonction_id = null
						if(fonctions_perso) {
							for (var i = 0; i < fonctions_perso.length; i++) {
								if(fonctions_perso[i].name) {
									fonction_id = await this.addFonction({fonction_label: fonctions_perso[i].name, fonction_code: ''})
									await this.addHorseContactFonction(fonction_id, horsecontact_id)
								}
							}
						}
					}
					
					return contact_id
				});
			},
			addPhoneNumber() {
				this.form.phones.push({
					phone_indicatif: '+33',
					phone_numero: '',
					phone_type: this.getTrad('tiers.phone_type.fixe'),
					phone_country: 'FRA',
				})
			},
			indicatif(country, key_phone) {
				this.form.phones[key_phone].phone_indicatif = this.indicatifs[country]
				this.form.phones[key_phone].phone_country = country
			},
			addFonctionInput() {
				if(this.fonction_perso.length >= 1 && this.boolean_fonction) {
					this.fonction_perso.push({name:""})	
				}

				this.boolean_fonction = true
			},
		},
		watch: {
		    'fonctions_formatted_flat'(val) {
		    	this.form.fonctions_formatted = this.fonctions_formatted_flat
	        }
		},
		components: {
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			ErrorAlert : () => import('GroomyRoot/components/Alert/ErrorAlert'),
			PhoneInput : () => import('GroomyRoot/components/Inputs/PhoneInput'),
			CountryInput : () => import('GroomyRoot/components/Inputs/CountryInput'),
		}
	}
</script>
